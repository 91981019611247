import { memo, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HighchartsExporting from 'highcharts/modules/exporting';

import highcharts3d from "highcharts/highcharts-3d";
import cylinder from "highcharts/modules/cylinder";
import grouped_categories from "highcharts-grouped-categories";

//import get_data_graficas from "./data_athena_graficas";


HighchartsExporting(Highcharts);


highcharts3d(Highcharts);
cylinder(Highcharts);
grouped_categories(Highcharts);

const GraphicsHighcharts = (props) => {
    var options = null;

    var athena = props.config[0];
    var config_grafica = props.config[1];
    var estilos_grafica = props.config[2];

    const [grafica_cargada, setGrafica_cargada] = useState();


    const carga_graficas = async () => {

        try {
            if (props.type === "pie") {

                var add_values_visibles = {};

                if (estilos_grafica.estilo_pipe.valor_visible) {
                    add_values_visibles = {
                        borderRadius: 5,
                        dataLabels: [{
                            enabled: true,
                            distance: 15,
                            format: '{point.name}',
                            style: {
                                fontSize: '1rem'
                            }
                        }, {
                            enabled: true,
                            distance: '-50%',
                            format: '{point.y}' + estilos_grafica.estilo_pipe.caracter_add_valor,
                            style: {
                                fontSize: '1.2rem',
                                textOutline: 'none'
                            }
                        }]
                    }
                }

                var modo_3d = {}

                if (estilos_grafica.estilo_pipe.dimencional) {
                    modo_3d = {
                        enabled: true,
                        alpha: 45,
                        beta: 0
                    }
                }

                var labes_position = {};

                if (estilos_grafica.hasOwnProperty("posicion_titulos")) {
                    if (estilos_grafica.posicion_titulos === "arriba") {
                        labes_position = {
                            layout: 'vertical',
                            align: 'left',
                            x: 90,
                            verticalAlign: 'center',
                            y: -15,
                            floating: true,
                            backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                        }
                    } else {
                        labes_position = {};
                    }
                }

                if (estilos_grafica.estilo_pipe.valor_visible) {

                    labes_position = {
                        enabled: false
                    }

                }


                var filtro = "";
                if (athena.hasOwnProperty("filtro")) {
                    filtro = athena.filtro;
                }

                var data = null;
                //hacemos la peticion de los datos
                //var data = await get_data_graficas(athena.type, filtro);

                var name_columna_generador_partes = config_grafica.partes_pie.fuentes_columnas_datos.name_columna_generador_partes;
                var name_columna_valores_partes = config_grafica.partes_pie.fuentes_columnas_datos.name_columna_valores;
                var objs_series = [];

                if (data !== null) {


                    data.result.forEach(rows => {

                        if (objs_series.length === 0) {
                            objs_series.push({
                                name: rows[name_columna_generador_partes],
                                y: null
                            });
                        } else {
                            var existe = false;
                            objs_series.forEach(obj => {
                                if (obj.name === rows[name_columna_generador_partes]) {
                                    existe = true;
                                }
                            });
                            if (!existe) {
                                objs_series.push({
                                    name: rows[name_columna_generador_partes],
                                    y: 0
                                });
                            }
                        }

                    });

                    data.result.forEach(rows => {

                        for (let i_objs_series = 0; i_objs_series < objs_series.length; i_objs_series++) {
                            if (objs_series[i_objs_series].name === rows[name_columna_generador_partes]) {
                                if (config_grafica.porcentaje) {
                                    objs_series[i_objs_series].y += (Number(rows[name_columna_valores_partes]) * 100);
                                } else {
                                    objs_series[i_objs_series].y += Number(rows[name_columna_valores_partes]);
                                }

                            }
                        }

                    });

                    //validamos los estilos
                    for (let i_objs_series = 0; i_objs_series < objs_series.length; i_objs_series++) {

                        for (let i_estilos = 0; i_estilos < config_grafica.partes_pie.estilo.length; i_estilos++) {
                            const element = config_grafica.partes_pie.estilo[i_estilos];

                            if (objs_series[i_objs_series].name === element.name_fuente) {
                                objs_series[i_objs_series]["color"] = element.color;
                                //break;
                            }
                        }
                    }
                    //console.log(objs_series);


                }




                options = {
                    chart: {
                        type: 'pie',

                        options3d: modo_3d,

                    },

                    exporting: {
                        enabled: false
                    },

                    title: {
                        text: config_grafica.titulo_grafica
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            depth: (estilos_grafica.estilo_pipe.dimencional ? 40 : 0),
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        },
                        series: add_values_visibles,
                    },
                    legend: labes_position,

                    tooltip: {
                        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}' + estilos_grafica.estilo_pipe.caracter_add_valor + '<br/>'
                    },
                    series: [{
                        name: config_grafica.partes_pie.titulo_general,
                        colorByPoint: true,
                        data: objs_series
                    }]
                }
            }

            if (props.type === "multi_graficos") {

                var labes_position = {};

                if (estilos_grafica.hasOwnProperty("posicion_titulos")) {
                    if (estilos_grafica.posicion_titulos === "arriba") {
                        labes_position = {
                            layout: 'vertical',
                            align: 'left',
                            x: 90,
                            verticalAlign: 'top',
                            y: -18,
                            floating: true,
                            backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                        }
                    } else {
                        labes_position = {};
                    }
                }

                var filtro = "";
                if (athena.hasOwnProperty("filtro")) {
                    filtro = athena.filtro;
                }

                var data = null;
                //hacemos la peticion de los datos
                //var data = await get_data_graficas(athena.type, filtro);



                //creamos la grafica.

                var header = [];

                //creamos el array que tendra los datos y ajustes de cada dibujo.
                var serries_dinamic = [];



                config_grafica.objetos_graficos.forEach(obj_g => {
                    serries_dinamic.push({
                        name_columna: obj_g.name_columna,
                        name: obj_g.titulo,
                        type: obj_g.tipo,
                        yAxis: 0,
                        data: [],
                        color: obj_g.color,
                        label: {
                            enabled: false
                        }
                    });
                });

                //console.log(serries_dinamic);


                if (data !== null) {
                    //tratamos los datos.
                    data.result.forEach(row => {
                        header.push(row[config_grafica.eje_x.name_columna]);

                        for (let i_obj_g = 0; i_obj_g < serries_dinamic.length; i_obj_g++) {
                            //const obj_g = serries_dinamic[i_obj_g];
                            if (isNaN(row[serries_dinamic[i_obj_g].name_columna])) {
                                row[serries_dinamic[i_obj_g].name_columna] = (row[serries_dinamic[i_obj_g].name_columna]).replace(",", "");
                                row[serries_dinamic[i_obj_g].name_columna] = (row[serries_dinamic[i_obj_g].name_columna]).replace("$", "");
                            }
                            serries_dinamic[i_obj_g].data.push(Number(row[serries_dinamic[i_obj_g].name_columna]));
                        }

                    });

                    //eliminamos key que ya no son utiles.

                    for (let i_obj_g = 0; i_obj_g < serries_dinamic.length; i_obj_g++) {
                        //const obj_g = serries_dinamic[i_obj_g];
                        delete serries_dinamic[i_obj_g].name_columna;
                    }
                }


                //console.log(serries_dinamic);

                options = {
                    chart: {
                        zoomType: 'xy'
                    },
                    title: {
                        text: config_grafica.titulo_grafica
                    },
                    exporting: {
                        enabled: false
                    },
                    xAxis: [{
                        categories: header,
                        crosshair: true,
                        labels: {
                            style: {
                                color: config_grafica.eje_x.color
                            }
                        }
                    }],
                    yAxis: [{ // Secondary yAxis
                        title: {
                            text: config_grafica.eje_y.titulo,
                            style: {
                                color: config_grafica.eje_y.color
                            }
                        },
                        labels: {
                            format: '{value}',
                            style: {
                                color: config_grafica.eje_y.color
                            }
                        },
                        //opposite: true
                    }],

                    /*
                    tooltip: {
                        shared: true
                    },
                    */
                    tooltip: {
                        shared: true,
                        //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                        //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}' + estilos_grafica.estilo_pipe.caracter_add_valor + '<br/>'
                    },
                    plotOptions: {
                        column: {
                            stacking: (config_grafica.interaccion_barras === 'divididas' ? '' : 'normal') // normal overlap  percent  stream
                        }
                    },
                    legend: labes_position,
                    /*
                    legend: {
                        layout: 'vertical',
                        align: 'left',
                        x: 90,
                        verticalAlign: 'top',
                        y: -18,
                        floating: true,
                        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                    },
                    */
                    series: serries_dinamic
                }
            }

            //console.log(options);
            setGrafica_cargada(<HighchartsReact highcharts={Highcharts} options={options} />);
        } catch (error) {
            setGrafica_cargada(<div></div>);
        }




    }



    useEffect(() => {
        carga_graficas();
    }, [])

    return (
        <div>
            {grafica_cargada}
        </div>
    );
}

export default memo(GraphicsHighcharts);







