import { createContext, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { useAuth } from "./loginContext.js";
import NavBar from "../components/AdminLTE/navbar.js";
import SideBar from "../components/AdminLTE/siderbar.js";
import Cookie from "js-cookie";
import Footer from "../components/AdminLTE/footer.js";

export const BOsContext = createContext();

//esta es para poder exportar todo lo que indicamos en values
export const useDashboard = () => {
    const context = useContext(BOsContext);
    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    } else {
        return context;
    }
};

//la forma de poder pasar datos entre vistes es creando este context, dentro de este tabien se pueden definir funciones para agregar,editar y asi, para esta manera de una vez cachar dichos datos.
export const BOsPlantilla = ({ children }) => {

    //peticion de datos del login
    const { user, user_autenticado, setUser_autenticado, save_date_user } = useAuth();
    const [apartados, setApartados] = useState("");

    let location = useLocation();

    const validate_cookies = () => {
        const cookies = Cookie.get();
        if (!cookies.UStok) {
            setUser_autenticado(false);
            save_date_user(null);
        }
    }

    useEffect(() => {

        validate_cookies();

        if (location.pathname === "/home") {
            setApartados("home")
        }

        if (location.pathname === "/buscar") {
            setApartados("buscar")
        }
        if (location.pathname === "/cartera_asignada") {
            setApartados("cartera_asignada")
        }

        if (location.pathname === "/expediente") {
            setApartados("expediente")
        }

        if (location.pathname === "/transparencia") {

            setApartados("transparencia")


        }
        if (location.pathname === "/capacitacion") {

            setApartados("capacitacion")

        }

        if (location.pathname === "/otros") {

            setApartados("otros")

        }

        if (location.pathname === "/perfil") {

            setApartados("perfil")

        }

        if (document.getElementById("collapseLayouts")) {
            if (!location.pathname.includes("cartera_asignada") && !location.pathname.includes("expediente")) {
                document.getElementById("collapseLayouts").classList.remove("show");
                console.log("activado");
            } else if (location.pathname.includes("cartera_asignada") || location.pathname.includes("expediente")) {
                document.getElementById("collapseLayouts").classList.add("show");
                console.log("apagado");
            }
        }
        //Advertising_campaigns

    }, [location.pathname]);

    //aqui indicamos los valores que deseamos exportar
    if (user_autenticado && user !== null) {
        return (
            <BOsContext.Provider value={{
                validate_cookies,
                apartados,
                setApartados
            }}>
                <NavBar />
                <div id="layoutSidenav">
                    <SideBar />
                    <div className="body_liso" id="layoutSidenav_content">
                        <main>
                            {children}
                        </main>
                        <Footer />
                    </div>
                </div>
            </BOsContext.Provider>
        )
    } else {
        return (
            <BOsContext.Provider value={{/*aqui van los metodos y variables a compartir*/ }}>
                {children}
            </BOsContext.Provider>
        )
    }
}