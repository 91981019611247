import { memo } from "react";

const DatosGeneralesDiv = () => {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <label className="titulo002">Datos generales</label>
                    <div className="div_interno">
                        <table className="table-hover">
                            <tbody>
                                <tr>
                                    <th>Expediente:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>No. Cuenta:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "200px" }}>Titular:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>RFC:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Fecha de Nacimiento:</th>
                                    <td>...</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <br></br>
                    <div className="div_interno">
                        <table className="table-hover">
                            <tbody>
                                <tr>
                                    <th>Institución Origen:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "200px" }}>Tipo de Contrato:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Desccripción del producto:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Fecha de apertura:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Saldo Total:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Capital:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Interes:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Gastos Legales:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>G. cobranza:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th>Cobranza:</th>
                                    <td>...</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <br></br>
                    <div className="div_interno">
                        <table className="table-hover w-100">
                            <tbody>
                                <tr>
                                    <th className="text-center" colSpan={"2"}>Comisíon</th>
                                </tr>
                                <tr>
                                    <th>Capital:</th>
                                    <td>...</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "200px" }}>Remanente:</th>
                                    <td>...</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
        </>

    );
}

export default memo(DatosGeneralesDiv);