import { createContext, useState, useContext, useEffect } from "react"
import Cookie from "js-cookie";
import axiosAWS from "../api/configAxios.js";

//para evitar errores creamos una variable global
var user = null;
//var user_autenticado = false;

export const AuthContext = createContext();

//esta es para poder exportar todo lo que indicamos en values
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    } else {
        return context;
    }
};

const delay = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
}

//la forma de poder pasar datos entre vistes es creando este context, dentro de este tabien se pueden definir funciones para agregar,editar y asi, para esta manera de una vez cachar dichos datos.
export const AuthProvider = ({ children }) => {
    //const [user, setUser] = useState(null);
    const [ip_public, setIp_public] = useState(null);
    const [style, setStyle] = useState(null);
    const [carga, setCarga] = useState(true);//false
    //esta hook lo usaremos para indicar que el usuario esta logeado
    //de igual manera lo indicamos en las values para poder usaralo o consultarlo en otros componentes
    const [user_autenticado, setUser_autenticado] = useState(false);
    const [id_lead, set_id_lead] = useState(null);


    //web 2.0
    const [result_peticiones, setResult_peticiones] = useState([]);

    const setUser = (data) => {
        user = data;
    }

    /*
    const setUser_autenticado = (data) =>{
        user_autenticado = data;
    }
    */

    const save_date_user = (user_values) => {
        if (user_values !== null) {
            setUser(user_values);
            //indicamos que el usuario en efecto esta logueado
            setUser_autenticado(true);
        }
    }

    const obtener_data = async () => {
        const cookies = Cookie.get();
        try {

            const result_validate = await axiosAWS.post("login/validate_session",{}, { headers: { 'Authorization': `Bearer ${cookies.UStok}` } }).catch(function (error) {
                if (error.response.status === 400) {
                    Cookie.remove("UStok");
                    setUser(null);
                    setUser_autenticado(false);
                    return
                }

                if (error.response.status >= 500) {
                    console.log("algo anda mal con la base de datos");
                    setUser(null);
                    setUser_autenticado(false);
                }
            });


            if (result_validate.data.user !== null) {


                if (result_validate.status >= 200 || result_validate.status <= 299) {
                    setUser_autenticado(true);
                    var set_data_user = result_validate.data.user;

                    if (JSON.stringify(set_data_user) !== JSON.stringify(user)) {
                        setUser(
                            set_data_user
                        );

                    }


                } else {
                    Cookie.remove("UStok");
                    //window.location.href = window.location.href;
                }
            } else {
                Cookie.remove("UStok");
                //window.location.href = window.location.href;
            }


        } catch (error) {

        }
    }

    const validar_token_back = async () => {

        setCarga(true);

        const cookies = Cookie.get();
        if (!user_autenticado) {
            document.body.classList.add("sidebar-closed");
            document.body.classList.add("sidebar-collapse");
            document.body.classList.remove("sidebar-open");
        }
        if (cookies.UStok) {
            if (user === null && cookies.UStok) {
                await obtener_data();
            } else {
                if (!user_autenticado) {
                    setUser_autenticado(true);
                }

            }

        } else {
            if (user_autenticado) {
                setUser_autenticado(false);
                setUser(null);
            }
        }


        setCarga(false);


    }


    


    useEffect(() => {
        validar_token_back();
    }, [user_autenticado]);


    //aqui indicamos los valores que deseamos exportar
    return (
        <AuthContext.Provider value={
            {
                user,
                user_autenticado,
                ip_public,
                setUser,
                setIp_public,
                save_date_user,
                setUser_autenticado
            }
        }>
            {
                carga ?
                    <div className="col-12 d-flex justify-content-center align-items-center h-100 body_liso">
                        <img src="/img/Loading_fondo_minus.png" className="img_loanding" alt="..."></img>
                    </div>
                    :
                    children
            }
        </AuthContext.Provider>
    )


}