import { useState, useEffect, memo } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import './login.css'
import Swal from 'sweetalert2';
import axiosAWS from '../../api/configAxios.js'

const Register = () => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [n_null, setN_null] = useState(false);
    const [co_null, setCo_null] = useState(false);
    const [c_null, setC_null] = useState(false);
    const [p_null, setP_null] = useState(false);
    const [text_p, setText_p] = useState('');
    const [text_c, setText_c] = useState('');
    const [text_n, setText_n] = useState('');
    const [text_Co, setText_co] = useState('');
    const [sw_ver, setSw_ver] = useState(false);
    const [button, setButton] = useState(true);
    var validar = false;

    const envio_form = async (e) => {
        e.preventDefault();

        if (user !== "") {
            validar = true;
            setC_null(false);
        } else {
            validar = false;
            setC_null(true);
            setText_c('campo requerido');
        }
        if (password !== "") {
            validar = true;
            setP_null(false);
        } else {
            validar = false;
            setP_null(true);
            setText_p("campo requerido")
        }

        if (validar) {
            setButton(false);
            try {
                const result_auth = await axiosAWS.post("validate/validar_correo", { user, password }).catch(function (error) {
                    if (error.response.status === 400) {
                        console.log("error 400");
                    }

                    if (error.response.status >= 500) {
                        console.log("algo anda mal con la el servidor");
                    }
                });


                setButton(true);

            } catch (error) {
                console.log("error servidorAWS");
                alert("Error con el servicio");
                setButton(true);
                return
            }
        } else {
            console.log("sin datos");
        }
    }

    const ver_contra = () => {
        if (document.getElementById("contra_cam").type === "password") {
            document.getElementById("contra_cam").type = "text";
            setSw_ver(true);
        } else {
            document.getElementById("contra_cam").type = "password";
            setSw_ver(false);
        }

    }

    //retornamos "la vista para el login"
    return (
        <>
            <div className='background-radial-gradient row d-flex justify-content-center align-items-center' style={{ height: "100vh" }}>
                <form >
                    <section className="overflow-hidden">
                        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                                    <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong" />
                                    <div id="radius-shape-2" className="position-absolute shadow-5-strong" />
                                    <div className="card bg-glass">

                                        <div className="card-body px-4 py-3 px-md-5" style={{ textAlign: "center" }}>
                                            <div className='form-outline mt-4'>
                                                <h1 className=" display-5 fw-bold " style={{ color: 'hsl(218, 81%, 95%)' }}>
                                                    <span style={{ color: 'rgb(64 96 154)' }}>Register</span>
                                                </h1>
                                            </div>
                                            {/* name input */}
                                            <div className="form-outline mt-5">
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" id="floatingInput" value={name} onChange={(e) => { setName(e.target.value); setN_null(false) }} placeholder="Andy" />
                                                    <label htmlFor="floatingInput">Name</label>
                                                </div>
                                                {c_null === true ? <label style={{ color: "red" }}>{text_c}</label> : <label></label>}
                                            </div>
                                            {/* Company input */}
                                            <div className="form-outline ">
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" id="company" value={company} onChange={(e) => { setCompany(e.target.value); setCo_null(false) }} placeholder="---" required/>
                                                    <label htmlFor="company">Company</label>
                                                </div>
                                                {c_null === true ? <label style={{ color: "red" }}>{text_c}</label> : <label></label>}
                                            </div>
                                            {/* Email input */}
                                            <div className="form-outline ">
                                                <div className="form-floating mb-3">
                                                    <input type="email" className="form-control" id="email" value={user} onChange={(e) => { setUser(e.target.value); setC_null(false) }} placeholder="name@example.com" required/>
                                                    <label htmlFor="email">Email address</label>
                                                </div>
                                                {c_null === true ? <label style={{ color: "red" }}>{text_c}</label> : <label></label>}
                                            </div>

                                            {/* Password input */}
                                            <div className="form-outline">
                                                <div className="input-group">
                                                    <div className="form-floating mb-3">
                                                        <input type="password" className="form-control" id="contra_cam" value={password} onChange={(e) => { setPassword(e.target.value); setP_null(false) }} placeholder="******" pattern='(?!000)([0-6]\d{2}|7([0-6]\d|7[012]))([ -])?(?!00)\d\d\3(?!0000)\d{4}' required/>
                                                        <label htmlFor="contra_cam">Password</label>
                                                    </div>
                                                    <button className="btn btn-outline-secondary mb-3" style={{ fontSize: "25px" }} type="button" id="ver_pas" onClick={ver_contra}>
                                                        {sw_ver ? <i className="ion-eye-disabled"></i> : <i className="ion-eye"></i>}
                                                    </button>
                                                </div>
                                                {p_null === true ? <label style={{ color: "red" }}>{text_p}</label> : <label></label>}<br />
                                            </div>
                                            <br />
                                            {/* Submit button */}
                                            <div className='mb-3' style={{ textAlign: "center" }}>

                                                <button className="alargar" style={{ marginBottom: "0px", color: "white", textDecoration: "none" }}>To Register</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>

            </div>
        </>

    );
}

export default memo(Register);