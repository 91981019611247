import { memo } from "react";

const MensajesTxtDiv = () => {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <label className="titulo002">Textos para Mensajaría</label><br></br>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Mensajes a titular</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Propuestas de pago</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Aclaraciones</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Información general</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Recordatorios de pago</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Incumplimientos</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Mensajes a Referencias</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Mensajes Cortos</button>
                    <button className="btn btn-pay m-1 btn-sm "><i className="bi bi-body-text"></i> Personalizadós</button>
                </div>
            </div>
        </>

    );
}

export default memo(MensajesTxtDiv);