import { useAuth } from "../../context/loginContext.js";
import { memo } from "react";

const PerfilU = () => {

    const { user } = useAuth();

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 titulo001">Perfil</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active subtitulo001">En este apartado visualizaras tus datos personales, asi como editar los mismos</li>
            </ol>


            <div className="row g-4">
                <div className="col-lg-6 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <label className="titulo002">Datos generales</label>
                            <div className="card bg-w p-2 mt-3">
                                <div className="row d-flex justify-content-center align-items-center h-100 mt-3">
                                    <div className="col-md-6 mb-2">
                                        <label>Clave</label>
                                        <input type="text" className="form-control" defaultValue={user.Clave} disabled />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Nombre</label>
                                        <input type="text" className="form-control" defaultValue={user.Nombre} disabled />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Contraseña</label>
                                        <div className="input-group mb-3">
                                            <input type="password" className="form-control" defaultValue={"iudhssiosb"} disabled />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="bi bi-pencil"></i> Editar</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <label className="titulo002">Datos de cobranza</label>
                            <div className="card bg-w p-2 mt-3">
                                <div className="row d-flex justify-content-center align-items-center h-100 mt-3">
                                    <div className="col-md-6 mb-2">
                                        <label>Alias</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" defaultValue={user.Alias} disabled />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="bi bi-pencil"></i> Editar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Télefono</label>
                                        <div className="input-group mb-3">
                                            <input type="number" className="form-control" defaultValue={user.TelefonoMovil} disabled />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="bi bi-pencil"></i> Editar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Correo electrónico</label>
                                        <div className="input-group mb-3">
                                            <input type="email" className="form-control" defaultValue={user.Email} disabled />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="bi bi-pencil"></i> Editar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-2 text-center">
                                        <button className="btn btn-pay"><i className="bi bi-repeat"></i> Refrescar cartera</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <label className="titulo002">Datos bancarios</label>
                            <div className="card bg-w p-2 mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default memo(PerfilU);