import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { AuthProvider } from "./context/loginContext.js";
import { BOsPlantilla } from "./context/dashboardContext.js";
import Login from './components/login/auth.js';
import Home from "./components/home/home.js";
import Register from "./components/login/register.js";
import CarteraAsig from "./components/cartera_asignada/cartera_asignada.js";
import Transparencia from "./components/transparencia/transparencia.js";
import Capacitacion from "./components/capacitacion/capacitacion.js";
import Expediente from "./components/cartera_asignada/expediente.js";
import ProtectedRoutes from "./protected/protectedRoutes.js";
import Error404 from "./components/errores/404.js";
import PerfilU from "./components/perfil/perfil.js";
import Buscar from "./components/buscar/buscar.js";
function App() {
  return (
    <>
      <AuthProvider>
        <HashRouter>
          <BOsPlantilla>
            <Routes>
              {/** fuera del dash */}
              <Route exact path="/" element={<Login />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />

              {/** dentro del dash y sesion activa*/}
              <Route element={<ProtectedRoutes />}>
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/perfil" element={<PerfilU />} />
                <Route exact path="/cartera_asignada" element={<CarteraAsig />} />
                <Route exact path="/transparencia" element={<Transparencia />} />
                <Route exact path="/capacitacion" element={<Capacitacion />} />
                <Route exact path="/expediente" element={<Expediente />} />
                <Route exact path="/buscar" element={<Buscar />} />

                {/**errores */}
                <Route exact path="*" element={<Error404 />} />
              </Route>

            </Routes>
          </BOsPlantilla>
        </HashRouter>
      </AuthProvider>

    </>



  );
}

export default App;
