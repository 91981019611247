import { memo } from "react";

const Footer = () => {
    return (
        <footer className="text-center text-lg-start text-muted bg-white_2">
            {/* Section: Social media */}
            <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                {/* Left */}
                <div className="me-5 d-none d-lg-block">
                    <span>Síguenos en nuestras redes sociales</span>
                </div>
                {/* Left */}
                {/* Right */}
                <div>
                    <a data-mdb-ripple-init className="btn text-white btn-floating m-1" style={{ backgroundColor: '#3b5998' }} href="" role="button"><i className="bi bi-facebook" /></a>
                    <a data-mdb-ripple-init className="btn text-white btn-floating m-1" style={{ backgroundColor: 'black' }} href="" role="button"><i className="bi bi-twitter-x" /></a>
                    <a data-mdb-ripple-init className="btn text-white btn-floating m-1" style={{ backgroundColor: '#0082ca' }} href="" role="button"><i className="bi bi-linkedin" /></a>
                </div>
                {/* Right */}
            </section>
            {/* Section: Social media */}
            {/* Section: Links  */}
            <section>
                <div className="container text-center text-md-start mt-5">
                    {/* Grid row */}
                    <div className="row mt-3">
                        {/* Grid column */}
                        <div className="col-md-6 col-lg-4 col-xl-3 mx-auto mb-4">
                            {/* Content */}
                            <h6 className="text-uppercase text-muted_1 fw-bold mb-4">
                                Telefonos
                            </h6>
                            <p >
                                <label className="titulo001">Soporte de cobranza</label><br></br>
                                <a className="m-1" href="tel:5526889166"><i className="fa fa-phone"></i></a>
                                <a className="m-1" href="https://wa.me/+525526889166/?text=Hola%20getPay.com.%20Me%20interesa%20consultar%20una%20duda."><i className="bi bi-whatsapp"></i></a>
                                <b>55 2688 9166</b><br></br>
                                Lunes a Viernes de 8:00 am a 7:00 pm y Sábado 7:00 am a 3:00 pm
                            </p>

                            <p>
                                <label className="titulo001">Recursos Humanos</label><br></br>
                                <a className="m-1" href="tel:5616545823"><i className="fa fa-phone"></i></a>
                                <a className="m-1" href="https://wa.me/+525526889166/?text=Hola%20getPay.com.%20Me%20interesa%20consultar%20una%20duda."><i className="bi bi-whatsapp"></i></a>
                                <b>56 1654 5823</b><br></br>
                                Lunes a Viernes de 8:00 am a 5:00 pm y Sábado 8:00 am a 1:00 pm
                            </p>
                        </div>
                        {/* Grid column */}
                        {/* Grid column */}
                        <div className="col-md-6 col-lg-4 col-xl-4 mx-auto mb-4">
                            {/* Links */}
                            <h6 className="text-uppercase text-muted_1 fw-bold mb-4">
                                Dirección
                            </h6>
                            <p>
                                <a className="m-1" href="tel:5616545823"><i className="bi bi-geo-alt-fill"></i></a>
                                Tlacotalpan 13 Interior 2ª, Col Roma Sur Alcaldía Cuauhtémoc, Ciudad de México, Código postal 06760
                            </p>
                        </div>
                        {/* Grid column */}
                        {/* Grid column */}
                        <div className="col-md-12 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            {/* Links */}
                            <h6 className="text-uppercase text-muted_1 fw-bold mb-4">¿TIENES ALGUNA PREGUNTAS?</h6>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Escríbenos" />
                                <button className="btn btn-pay" type="button" id="button-addon2">Enviar</button>
                            </div>

                        </div>
                        {/* Grid column */}
                    </div>
                    {/* Grid row */}
                </div>
            </section>
            {/* Section: Links  */}
            {/* Copyright */}
            <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                © 2024 Todos los derechos reservados para <b className="text-muted_1">GetPay</b>
            </div>
            {/* Copyright */}
        </footer>

    );
}

export default memo(Footer);