import { useAuth } from "../context/loginContext";
import { Outlet, Navigate } from "react-router-dom";
import Cookie from "js-cookie";
import { memo } from "react";

function ProtectedRoutes() {
    const { carga, user, user_autenticado,setUser } = useAuth();
    const cookie = Cookie.get();
    
    if (!cookie.UStok) {
        setUser(null);
        //window.location.reload();
        return <Navigate to="/login" replace />
    }

    if (!carga) {
        if (!user_autenticado && user === null) {
            //setUser(null);
            //window.location.reload();
            return <Navigate to="/login" replace />
        }
    }

    //de esta forma indicamos que continue con el compomente que esta dentro, esto dado caso de que si lo sea.
    return (
        <Outlet />
    );
}
export default memo(ProtectedRoutes);