import { Link, useLocation } from "react-router-dom";
import NavBar from "../AdminLTE/navbar";
import SideBar from "../AdminLTE/siderbar";
import Footer from "../AdminLTE/footer";
import { memo } from "react";
const Capacitacion = () => {
    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 titulo001">Capacitación</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active subtitulo001">¿No recuerdas algo?, no te preocupes, siempre podrás ver las enseñanzas de cada una de las unidades</li>
            </ol>
            {/**
                                <div className="card">
                                    <div className="card-body">
                                    </div>
                                </div>
                            */}

            <div className="container">
                <div className="row g-4">

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/video_lent.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438077605" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 1
                            </div>

                            <div className="card-text">
                                <p>¿Que es la cobranza?</p>
                            </div>

                            {/*<label className="card-button">¿Que es la cobranza?</label>*/}
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/asesores.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438337198" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 2
                            </div>

                            <div className="card-text">
                                <p>Asesores financieros</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/Calling.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438338205" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 3
                            </div>

                            <div className="card-text">
                                <p>Inicio de la llamada</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/Agreement.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438380402" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 4
                            </div>

                            <div className="card-text">
                                <p>Negociación</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/Duplicate.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438380802" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 5
                            </div>

                            <div className="card-text">
                                <p>Objeciones</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/Done-rafiki.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438381167" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 6
                            </div>

                            <div className="card-text">
                                <p>Tecnica de cierre</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/Done-pana.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438381514" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 7
                            </div>

                            <div className="card-text">
                                <p>Los clientes</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="card-sl">
                            <div className="card-image d-flex justify-content-center align-items-center h-100">
                                {/*<img src="/img/Lawyer.png" />*/}
                                <iframe src="https://player.vimeo.com/video/438381796" allow="autoplay; fullscreen" allowFullScreen className="traing-img" />
                            </div>
                            <label className="card-action" id="maps_001"><i className="bi bi-info-circle" /></label>
                            <div className="card-heading">
                                Unidad 8
                            </div>

                            <div className="card-text">
                                <p>Aspectos legales</p>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default memo(Capacitacion);


/*
<div className="col-md-4 asist_chat_div" >
                                        <label className="titulo002" style={{ position: "sticky", top: "60px" }}>Asistente</label>
                                        <div className="card" style={{ position: "sticky", top: "100px" }}>
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </div>
*/