import { Link, useLocation } from "react-router-dom";
import NavBar from "../AdminLTE/navbar";
import SideBar from "../AdminLTE/siderbar";
import Footer from "../AdminLTE/footer";
import './home.css'
import { memo } from "react";
const Home = () => {
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4 titulo001">Inicio</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active subtitulo001">Bienvenido</li>
                </ol>
                {/**
                                <div className="card">
                                    <div className="card-body">
                                    </div>
                                </div>
                            */}

                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center h-100 g-4">
                        <div className="col-md-4 col-sm-6 ">
                            <Link className="card text-center card_transparent " to={"/buscar"} style={{ textDecoration: "none" }}>
                                <div className="card-body">
                                    <img src="./img/2827397.png" className="w-50" />
                                    <h1 className="mt-4 titulo002">Buscar</h1>
                                    <label className="subtitulo001 text-center">ir al modulo</label>
                                </div>
                            </Link>

                        </div>
                        <div className="col-md-4 col-sm-6 text-center">
                            <Link className="card text-center card_transparent" to={"/cartera_asignada"} style={{ textDecoration: "none" }}>
                                <div className="card-body">
                                    <img src="./img/4163464.png" className="w-50" />
                                    <h1 className="mt-4 titulo002">Cartera Asignada</h1>
                                    <label className="subtitulo001 text-center">ir al modulo</label>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center">
                            <Link className="card text-center card_transparent" to={"/transparencia"} style={{ textDecoration: "none" }}>
                                <div className="card-body">
                                    <img src="./img/4565432.png" className="w-50" />
                                    <h1 className="mt-4 titulo002">Transparencia</h1>
                                    <label className="subtitulo001 text-center">ir al modulo</label>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center">
                            <Link className="card text-center card_transparent" to={"/capacitacion"} style={{ textDecoration: "none" }}>
                                <div className="card-body">
                                    <img src="./img/8294.png" className="w-50" />
                                    <h1 className="mt-4 titulo002">Capacitación</h1>
                                    <label className="subtitulo001 text-center">ir al modulo</label>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center">
                            <Link className="card text-center card_transparent" to={"/otros"} style={{ textDecoration: "none" }}>
                                <div className="card-body">
                                    <img src="./img/20943566.png" className="w-50" />
                                    <h1 className="mt-4 titulo002">Otros</h1>
                                    <label className="subtitulo001 text-center">ir al modulo</label>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default memo(Home);