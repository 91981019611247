import { memo } from "react";

const ModalesInsertDataExp = () => {
    return (
        <>
            <div className="modal fade" id="m_notas" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 txt_label_titulo" id="exampleModalLabel">Agregar Nota</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6 mb-2">
                                    <label className="txt_label">Acción:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Llamada</option>
                                        <option value={2}>WhatsApp</option>
                                        <option value={3}>SMS</option>
                                        <option value={4}>Correo</option>
                                        <option value={5}>Visita</option>
                                        <option value={6}>Carteo</option>
                                        <option value={7}>Blaster</option>
                                        <option value={8}>Buró de Crédito</option>
                                        <option value={9}>Otro</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Contacto:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Titular</option>
                                        <option value={2}>Familiar</option>
                                        <option value={3}>Trabajo</option>
                                        <option value={4}>3ro</option>
                                        <option value={5}>Sin Contacto</option>
                                        <option value={6}>Otro</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Bloque Resultado:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Gestión</option>
                                        <option value={2}>Pago</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Resultado:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                    </select>
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Teléfono:</label>
                                    <input type="tel" className="form-control"></input>
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Nota:</label>
                                    <textarea className="form-control" rows={5}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="m_convenio" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 txt_label_titulo" id="exampleModalLabel">Agregar Convenio</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-4 mb-2">
                                    <label className="txt_label">Tipo:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Simple</option>
                                        <option value={2}>Legal</option>
                                    </select>
                                </div>
                                <div className="col-sm-4  mb-2">
                                    <label className="txt_label">Buro de crédito:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>MOP-01</option>
                                        <option value={2}>MOP-97</option>
                                        <option value={3}>Eliminación</option>
                                        <option value={4}>Por Definir</option>
                                    </select>
                                </div>
                                <div className="col-sm-4  mb-2">
                                    <label className="txt_label">Saldo a liquidar:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Saldo Total</option>
                                        <option value={2}>Remanente</option>
                                        <option value={3}>Capital</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Formato de pago:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Efectivo</option>
                                        <option value={2}>Transferencia</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-3">
                                    <label className="txt_label">Beneficiario:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                    </select>
                                </div>
                                <div className="col-sm-12  mb-2 text-secondary">
                                    <i className="bi bi-info-circle"></i> Indica la fecha y el monto de pago, si son mas de uno, dale clic en él <i className="bi bi-plus-square text-primary"></i> para agregar otro, si te equivocas dale clic en <i className="bi bi-dash-circle text-danger"></i> para eliminarlo.
                                </div>
                                <div className="col-sm-6  mb-3">
                                    <label className="txt_label">Fecha del pago:</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="col-sm-6  mb-3">
                                    <label className="txt_label">Monto:</label>
                                    <div className="input-group mb-3">
                                        <input type="number" className="form-control" min={1} />
                                        <button className="btn btn-outline-primary" type="button" id="button-addon2"><i className="bi bi-plus-lg"></i></button>
                                    </div>
                                </div>

                                <div className="col-sm-12  mb-2">
                                    <div className="card bg-w">
                                        <table className="table table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th className="text-center">Fecha del pago</th>
                                                    <th className="text-center">Monto del Pago</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr>
                                                    <td className="text-end" colSpan={2}><b>Total</b> $0.00</td>
                                                </tr>

                                            </tbody>

                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="m_correo" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 txt_label_titulo" id="exampleModalLabel">Agregar Correo</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6 mb-2">
                                    <label className="txt_label">Contacto:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Titular</option>
                                        <option value={2}>Familiar</option>
                                        <option value={3}>Trabajo</option>
                                        <option value={4}>3ro</option>
                                        <option value={5}>Sin Contacto</option>
                                        <option value={6}>Otro</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Origen información:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={3}>Gestión teléfonica</option>
                                        <option value={4}>Correo electronico</option>
                                        <option value={5}>Buro de Credito</option>
                                        <option value={6}>Circulo de Credito</option>
                                        <option value={7}>Internet (Google)</option>
                                        <option value={8}>Facebook</option>
                                        <option value={13}>Otros</option>
                                    </select>
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Correo:</label>
                                    <input type="email" className="form-control"></input>
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Referencia ó comentatio:</label>
                                    <textarea className="form-control" rows={5}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="m_telefono" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 txt_label_titulo" id="exampleModalLabel">Agregar Telefono</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6 mb-2">
                                    <label className="txt_label">Contacto:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Titular</option>
                                        <option value={2}>Familiar</option>
                                        <option value={3}>Trabajo</option>
                                        <option value={4}>3ro</option>
                                        <option value={5}>Sin Contacto</option>
                                        <option value={6}>Otro</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Origen información:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={3}>Gestión teléfonica</option>
                                        <option value={4}>Correo electronico</option>
                                        <option value={5}>Buro de Credito</option>
                                        <option value={6}>Circulo de Credito</option>
                                        <option value={7}>Internet (Google)</option>
                                        <option value={8}>Facebook</option>
                                        <option value={13}>Otros</option>
                                    </select>
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Tipo Teléfono:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Local</option>
                                        <option value={2}>Celular</option>
                                    </select>
                                </div>
                                <div className="col-sm-3  mb-2">
                                    <label className="txt_label">Extension:</label>
                                    <input type="tel" className="form-control"></input>
                                </div>
                                <div className="col-sm-9  mb-2">
                                    <label className="txt_label">Teléfono:</label>
                                    <input type="tel" className="form-control"></input>
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Referencia ó comentatio:</label>
                                    <textarea className="form-control" rows={5}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="m_direccion" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 txt_label_titulo" id="exampleModalLabel">Agregar Dirección</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6 mb-2">
                                    <label className="txt_label">Contacto:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Titular</option>
                                        <option value={2}>Familiar</option>
                                        <option value={3}>Trabajo</option>
                                        <option value={4}>3ro</option>
                                        <option value={5}>Sin Contacto</option>
                                        <option value={6}>Otro</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Origen información:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={3}>Gestión teléfonica</option>
                                        <option value={4}>Correo electronico</option>
                                        <option value={5}>Buro de Credito</option>
                                        <option value={6}>Circulo de Credito</option>
                                        <option value={7}>Internet (Google)</option>
                                        <option value={8}>Facebook</option>
                                        <option value={13}>Otros</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Calle:</label>
                                    <input type="text" className="form-control"></input>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Colonia:</label>
                                    <input type="text" className="form-control"></input>
                                </div>
                                <div className="col-sm-4  mb-2">
                                    <label className="txt_label">Municipio:</label>
                                    <input type="text" className="form-control"></input>
                                </div>
                                <div className="col-sm-4  mb-2">
                                    <label className="txt_label">Estado:</label>
                                    <input type="text" className="form-control"></input>
                                </div>
                                <div className="col-sm-4  mb-2">
                                    <label className="txt_label">CP:</label>
                                    <input type="tel" className="form-control"></input>
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Referencia ó comentatio:</label>
                                    <textarea className="form-control" rows={5}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="m_c_pago" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 txt_label_titulo" id="exampleModalLabel">Agregar Comprobante de pago</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <input type="file" className="form-control" />
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <label className="txt_label">Forma de pago:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Efectivo</option>
                                        <option value={2}>Transferencia</option>
                                    </select>
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Beneficiario:</label>
                                    <select className="form-select form-select-sm">
                                        <option disabled>.:Selecciona:.</option>
                                        <option value={1}>Metro Invest, S.A. DE C.V.</option>
                                        <option value={2}>Adeudos Vencidos, S.A. DE C.V.</option>
                                        <option value={3}>AXIS Logistica y Distribucion, S.A. DE C.V.</option>
                                        <option value={4}>Asesores Bona Fide, S.A. DE C.V.</option>
                                        <option value={5}>Cuentas Vencidas de Occidente, S.A. DE C.V.</option>
                                        <option value={6}>Administradora Mexiquense, S.A. DE C.V.</option>
                                        <option value={7}>F Cobranza Comercial, S.A. DE C.V.</option>
                                        <option value={8}>Administradora de Créditos Pasivos, S.A. de C.V.</option>
                                        <option value={9}>Administradora De Pasivos Del Centro, S.A. de C.V.</option>
                                    </select>
                                </div>

                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Fecha del pago:</label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Monto:</label>
                                    <input type="number" className="form-control" />
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Folio:</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="col-sm-6  mb-2">
                                    <label className="txt_label">Sucursal:</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="col-sm-12  mb-2">
                                    <label className="txt_label">Concepto:</label>
                                    <textarea className="form-control" rows={2}></textarea>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default memo(ModalesInsertDataExp);