import { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import NavBar from "../AdminLTE/navbar";
import SideBar from "../AdminLTE/siderbar";
import Footer from "../AdminLTE/footer";
import MensajesTxtDiv from "./txt_mensajes.js";
import DatosGeneralesDiv from "./datos_generales.js";
import Modales_insert_data from "./Modales_insert_data.js";
const Expediente = () => {
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4 titulo001">Expediente</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active subtitulo001">Aquí encontrarás información detallada del expediente, así como todas las opciones disponibles de agregar y visualizar.</li>
                </ol>

                <div className="row g-3">
                    <div className="col-md-4">
                        <DatosGeneralesDiv />
                        <br></br>
                        <MensajesTxtDiv />
                    </div>

                    <div className="col-md-8">
                        <div className="col-12 text-center mb-5">
                            <button className="btn btn-pay-w m-1" data-bs-toggle="modal" data-bs-target="#m_notas"><i className="bi bi-pencil"></i> Nota</button>
                            <button className="btn btn-pay-w m-1" data-bs-toggle="modal" data-bs-target="#m_convenio"><i className="bi bi-file-text"></i> Convenio</button>
                            <button className="btn btn-pay-w m-1" data-bs-toggle="modal" data-bs-target="#m_telefono"><i className="bi bi-telephone"></i> Teléfono</button>
                            <button className="btn btn-pay-w m-1" data-bs-toggle="modal" data-bs-target="#m_direccion"><i className="bi bi-geo-alt"></i> Dirección</button>
                            <button className="btn btn-pay-w m-1" data-bs-toggle="modal" data-bs-target="#m_correo"><i className="bi bi-envelope"></i> Correo</button>
                            <button className="btn btn-pay-w m-1" data-bs-toggle="modal" data-bs-target="#m_c_pago"><i className="bi bi-card-heading"></i> Comprobante de pago</button>
                            <button className="btn btn-pay-w m-1" disabled><i className="bi bi-currency-exchange"></i> Alternativas</button>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="nota-tab" data-bs-toggle="tab" data-bs-target="#nota-tab-pane" type="button" role="tab" aria-controls="nota-tab-pane" aria-selected="true">Notas</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#Convenio-tab-pane" type="button" role="tab" aria-controls="Convenio-tab-pane" aria-selected="false">Contacto</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="telefono-tab" data-bs-toggle="tab" data-bs-target="#mpagos-tab-pane" type="button" role="tab" aria-controls="mpagos-tab-pane" aria-selected="false">Metodos de Pago</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="direccion-tab" data-bs-toggle="tab" data-bs-target="#cobranza-tab-pane" type="button" role="tab" aria-controls="cobranza-tab-pane" aria-selected="false">Cobranza</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent" style={{ backgroundColor: "white" }}>

                                            <div className="tab-pane fade show active" id="nota-tab-pane" role="tabpanel" aria-labelledby="nota-tab" tabIndex={0}>
                                                <div className="card-body">
                                                    {/** <label className="titulo002">Notas</label>*/}

                                                    <div className="div_interno" style={{ minHeight: "200px", overflowY: "auto", maxHeight: "450px" }}>

                                                        <div className="card bg-w">
                                                            <div className="card-header">
                                                                Ejemplo
                                                            </div>
                                                            <div className="card-body">
                                                                <p >With supporting text below as a natural lead-in to additional content.</p>
                                                                <div className="row">
                                                                    <div className="col-sm-6 text-start">
                                                                        <span className="txt_color_card">2024-06-24 16:50:01</span>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <span className="txt_color_card">text adiciona</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="Convenio-tab-pane" role="tabpanel" aria-labelledby="Convenio-tab" tabIndex={0}>
                                                <div className="card-body">
                                                    {/** <label className="titulo002">Contacto</label>  */}

                                                    <div className="div_interno" style={{ minHeight: "200px", overflowY: "auto", maxHeight: "450px" }}>

                                                        <div className="card bg-w">
                                                            <div className="card-header">
                                                                Correos
                                                            </div>
                                                            <div className="card-body">

                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-sm-6 text-start">
                                                                            <span className="txt_color_card">Titular (Expediente)</span>
                                                                        </div>
                                                                        <div className="col-sm-6 text-end">
                                                                            <span className="txt_color_card">2024-06-24</span>
                                                                        </div>
                                                                        <label>ejemplo@gmail.com</label>
                                                                        <label className="txt_color_card">ejemplo</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="card bg-w mt-3">
                                                            <div className="card-header">
                                                                Teléfonos
                                                            </div>
                                                            <div className="card-body">

                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-sm-6 text-start">
                                                                            <span className="txt_color_card">Titular (Gestión teléfonica)</span>
                                                                        </div>
                                                                        <div className="col-sm-6 text-end">
                                                                            <span className="txt_color_card">2024-06-24</span>
                                                                        </div>
                                                                        <label>5555555555</label>
                                                                        <label className="txt_color_card">ejemplo</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="mpagos-tab-pane" role="tabpanel" aria-labelledby="mpagos-tab" tabIndex={0}>
                                                <div className="card-body">

                                                    <div className="div_interno" style={{ minHeight: "200px", overflowY: "auto", maxHeight: "450px" }}>

                                                        <div className="card bg-w">
                                                            <div className="card-header">
                                                                Ventanilla BBVA Bancomer
                                                            </div>
                                                            <div className="card-body">
                                                                <table className="table-hover">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Banco:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Beneficiario:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={{ width: "200px" }}>Cuenta:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Interbancaria:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Concepto:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className="card bg-w mt-3">
                                                            <div className="card-header">
                                                                Practicaja y Bancomer Movil
                                                            </div>
                                                            <div className="card-body">
                                                                <table className="table-hover">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Banco:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Beneficiario:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={{ width: "200px" }}>Cuenta:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Concepto:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className="card bg-w mt-3">
                                                            <div className="card-header">
                                                                Tranferencia de otros bancos
                                                            </div>
                                                            <div className="card-body">
                                                                <table className="table-hover">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Banco:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Beneficiario:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={{ width: "200px" }}>Cuenta:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Concepto:</th>
                                                                            <td>...</td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="cobranza-tab-pane" role="tabpanel" aria-labelledby="cobranza-tab" tabIndex={0}>
                                                <div className="card-body">

                                                    <div className="div_interno" style={{ minHeight: "200px", overflowY: "auto", maxHeight: "450px" }}>

                                                        <div className="card bg-w">
                                                            <table className="table table-hover">
                                                                <thead className="table-primary">
                                                                    <tr>
                                                                        <th>Fecha del pago</th>
                                                                        <th>Monto del Pago</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>2024-07-25</td>
                                                                        <td>$700.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2024-07-25</td>
                                                                        <td>$700.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2024-07-25</td>
                                                                        <td>$700.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-end" style={{ fontWeight: "bold" }}>Total</td>
                                                                        <td>$2,100.00</td>
                                                                    </tr>

                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modales_insert_data />



        </>
    );
}

export default memo(Expediente);