import { memo, useEffect, useState } from "react";
import NavBar from "../AdminLTE/navbar";
import SideBar from "../AdminLTE/siderbar";
import CardAsigExp from "./card_exp_asig.js";
const CarteraAsig = () => {

    const [carga_text, setCarga_text] = useState("¡Sin resultados!");
    const [result_phone, setResult_phone] = useState([]);


    useEffect(() => {
        const add_card = [...result_phone];
        add_card.push(<CardAsigExp data={({ id_cuenta: 1, Nombre_Titular: "Antonio Juarez Andrade", RFC: "JUAA980612HI1", Cuenta: "34567890987654", Ejecutivo: "Miguel R.", Fecha_Nota: (new Date()), Telefono: "5627334521", Cobranza: 0, SaldoTotal: 50000 })} key={1} />);
        setResult_phone(add_card);
    }, [])

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 titulo001">Cartera Asignada</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active subtitulo001">Aqui encontraras los expedientes que se te asignaron</li>
            </ol>
            {/**
                             <div className="card mb-5">
                                <div className="card-body">
                                    <label className="titulo002">Filtro</label>
                                </div>
                            </div>
                             */}



            <div className="container">
                <div className="row d-flex justify-content-center align-items-center h-100 g-3">
                    {
                        result_phone.length === 0 ?
                            <div className="col-sm-6" style={{ textAlign: "center" }}>
                                <label className="titulo002">{carga_text}</label><br />
                                {carga_text === "¡Sin resultados!" ?
                                    <img title="actividad" src={"/img/no_data.png"} alt="" width={"350px"} />
                                    :
                                    <img title="actividad" src={"/img/progress-unscreen.gif"} alt="" width={"50px"} />
                                }
                            </div>
                            :

                            result_phone.map((item, index) => {

                                return (
                                    item
                                );


                            })

                    }
                </div>
            </div>
        </div>
    );
}

export default memo(CarteraAsig);