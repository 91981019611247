import { useState, useEffect, memo } from 'react';
import { useAuth } from "../../context/loginContext.js";
import { Link, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import './login.css'
import Swal from 'sweetalert2';
import axiosAWS from '../../api/configAxios.js'

const Login = () => {

    const { save_date_user, user_autenticado, setIp_public, ip_public } = useAuth();

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [c_null, setC_null] = useState(false);
    const [p_null, setP_null] = useState(false);
    const [text_p, setText_p] = useState('');
    const [text_c, setText_c] = useState('');
    const [sw_ver, setSw_ver] = useState(false);
    const [button, setButton] = useState(true);
    const navigate = useNavigate();
    var validar = false;
    document.getElementById("body_main").classList.remove("body_liso");

    const envio_form = async (e) => {
        e.preventDefault();

        if (user !== "") {
            validar = true;
            setC_null(false);
        } else {
            validar = false;
            setC_null(true);
            setText_c('campo requerido');
        }
        if (password !== "") {
            validar = true;
            setP_null(false);
        } else {
            validar = false;
            setP_null(true);
            setText_p("campo requerido")
        }

        if (validar) {
            setButton(false);
            try {
                const result_auth = await axiosAWS.post("login/create_session", { CUsuario: user, Secret: password, ipLocal: "1.1.1.1", ipPublic: ip_public }).catch(function (error) {
                    if (error.response.status === 400) {
                        console.log("error 400");
                    }

                    if (error.response.status >= 500) {
                        console.log("algo anda mal con la el servidor");
                    }
                });
                setButton(true);

                console.log(result_auth);

                if (result_auth.status >= 200 || result_auth.status <= 299) {
                    if (result_auth.data.hasOwnProperty("token")) {
                        if (result_auth.data.token !== null && result_auth.data.user.hasOwnProperty("Clave")) {
                            const newLocal = 0.625;
                            Cookie.set('UStok', result_auth.data.token, { expires: newLocal });
                            save_date_user(result_auth.data.user);
                        }else{
                            alert("Verifica tus credenciales");
                        }

                    }

                } else {
                    alert("usuario denegado");
                }


            } catch (error) {
                
                console.log("error servidorAWS");
                alert("Error con el servicio");
                setButton(true);
                return
            }
        } else {
            console.log("sin datos");
        }
    }

    const ver_contra = () => {
        if (document.getElementById("contra_cam").type === "password") {
            document.getElementById("contra_cam").type = "text";
            setSw_ver(true);
        } else {
            document.getElementById("contra_cam").type = "password";
            setSw_ver(false);
        }

    }
    window.addEventListener("load", function (event) {
        document.getElementById("floatingInput").focus();
        window.scroll({
            top: 140,
            behavior: "smooth",
        });
    });

    useEffect(() => {
        const cookie = Cookie.get();
        if (user_autenticado && cookie.UStok) {
            //si esta ya se aplico el logue, entonces lo mandamos al "home"
            navigate("/home");
        }
        //para optener la ip del usuario.
        const URL_API = "https://api.ipify.org/?format=json";
        fetch(URL_API)
            .then(respuestaRaw => respuestaRaw.json())
            .then(respuesta => {
                setIp_public(respuesta.ip);
                //console.log(respuesta.ip);
            });
    });


    //retornamos "la vista para el login"
    return (
        <section className="d-flex justify-content-center align-items-center h-100">
            <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
                <div className="row gx-lg-5 align-items-center">
                    <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
                        <h1 className="my-5 display-5 fw-bold ls-tight" style={{ color: '#39888c' }}>
                            ¡Hola! <br />
                            <span style={{ color: '#2D4356' }}>Bienvenido</span>
                        </h1>
                        <p className="mb-4 opacity-70" style={{ color: 'black', textShadow: "0 0 20px black" }}>
                            Este es el sistema de gestión de GatPay, si aun no formas parte de nuestro equipo, <Link className="small" to={"/correo_validate"} style={{ marginBottom: "0px", color: '#39888c', fontWeight: "bold" }}>solicita registro</Link>.
                        </p>
                    </div>
                    <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                        <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong" />
                        <div id="radius-shape-2" className="position-absolute shadow-5-strong" />
                        <div className="card bg-glass">
                            <div className='col-12 text-center mt-4'>
                                <img src={"/logo.png"} alt="login form" title="Bienvenido al sistema" className="w-50" />
                            </div>
                            <div className="card-body px-4 py-5 px-md-5">
                                <form onSubmit={(e) => { envio_form(e) }} id='form_env'>
                                    {/* Email input */}
                                    <div className="form-outline mb-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" value={user} onChange={(e) => { setUser(e.target.value); setC_null(false) }} placeholder="name@example.com" />
                                            <label htmlFor="floatingInput">Ususario</label>
                                        </div>
                                        {c_null === true ? <label style={{ color: "red" }}>{text_c}</label> : <label></label>}
                                    </div>

                                    {/* Password input */}
                                    <div className="form-outline mb-4">
                                        <div className="input-group ">
                                            <div className="form-floating mb-3">
                                                <input type="password" className="form-control" id="contra_cam" value={password} onChange={(e) => { setPassword(e.target.value); setP_null(false) }} placeholder="******" />
                                                <label htmlFor="contra_cam">Contraseña</label>
                                            </div>
                                            <button className="btn btn-outline-secondary mb-3" style={{ fontSize: "25px" }} type="button" id="ver_pas" onClick={ver_contra}>
                                                {sw_ver ? <i className="ion-eye-disabled"></i> : <i className="ion-eye"></i>}
                                            </button>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-6'>{p_null === true ? <label style={{ color: "red" }}>{text_p}</label> : <label></label>}<br /></div>
                                            <div className='col-md-6 text-center'><Link className="small text-muted" to={"/correo_validate"} style={{ marginBottom: "0px" }}>¿No recuerdas tu contraseña?</Link></div>
                                        </div>

                                    </div>


                                    {/* Submit button */}
                                    <div className="pt-1 " style={{ textAlign: "center" }}>

                                        {
                                            button === true ?
                                                <>
                                                    {/**<button className="alargar" type="submit">Ingresar</button> */}

                                                    <button className="alargar" style={{ marginBottom: "0px", textDecoration: "none" }}>Ingresar</button>
                                                </>

                                                :
                                                < button className="alargar" disabled>Validando...</button>
                                        }
                                        <br /> <br />
                                        <Link className="small text-muted" to={"/correo_validate"} style={{ marginBottom: "0px" }}>¿No formas parte de nuestro equipo?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </section >

    );
}

export default memo(Login);


/*

<section className="d-flex justify-content-center align-items-center h-100">
            <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
                <div className="row gx-lg-5 align-items-center">

                    <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                        <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong" />
                        <div id="radius-shape-2" className="position-absolute shadow-5-strong" />
                        <div className="card bg-glass">
                            <div className='col-12 text-center mt-4'>
                                <img src={"/logo.png"} alt="login form" title="Bienvenido al sistema" className="w-50" />
                            </div>
                            <div className="card-body px-4 py-5 px-md-5">
                                <form onSubmit={(e) => { envio_form(e) }} id='form_env'>
                              
                                    <div className="form-outline mb-4">
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" id="floatingInput" value={user} onChange={(e) => { setUser(e.target.value); setC_null(false) }} placeholder="name@example.com" />
                                            <label htmlFor="floatingInput">Ususario</label>
                                        </div>
                                        {c_null === true ? <label style={{ color: "red" }}>{text_c}</label> : <label></label>}
                                    </div>

                                    <div className="form-outline mb-4">
                                        <div className="input-group ">
                                            <div className="form-floating mb-3">
                                                <input type="password" className="form-control" id="contra_cam" value={password} onChange={(e) => { setPassword(e.target.value); setP_null(false) }} placeholder="******" />
                                                <label htmlFor="contra_cam">Contraseña</label>
                                            </div>
                                            <button className="btn btn-outline-secondary mb-3" style={{ fontSize: "25px" }} type="button" id="ver_pas" onClick={ver_contra}>
                                                {sw_ver ? <i className="ion-eye-disabled"></i> : <i className="ion-eye"></i>}
                                            </button>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-6'>{p_null === true ? <label style={{ color: "red" }}>{text_p}</label> : <label></label>}<br /></div>
                                            <div className='col-md-6 text-center'><Link className="small text-muted" to={"/correo_validate"} style={{ marginBottom: "0px" }}>¿No recuerdas tu contraseña?</Link></div>
                                        </div>

                                    </div>


                                    <div className="pt-1 " style={{ textAlign: "center" }}>

                                        {
                                            button === true ?
                                                <>
                                                    

                                                    <Link className="alargar" to={"/home"} style={{ marginBottom: "0px", textDecoration: "none" }}>Ingresar</Link>
                                                </>

                                                :
                                                < button className="alargar_2" disabled>Validando...</button>
                                        }
                                        <br /> <br />
                                        <Link className="small text-muted" to={"/correo_validate"} style={{ marginBottom: "0px" }}>¿No formas parte de nuestro equipo?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
                        <h1 className="my-5 display-5 fw-bold ls-tight" style={{ color: 'hsl(218, 81%, 95%)' }}>
                            ¡Hola! <br />
                            <span style={{ color: 'hsl(218, 81%, 75%)' }}>Bienvenido</span>
                        </h1>
                        <p className="mb-4 opacity-70" style={{ color: 'hsl(218, 81%, 85%)' }}>
                            Este es el sistema de gestión de GatPay, si aun no formas parte de nuestro equipo, <Link className="small" to={"/correo_validate"} style={{ marginBottom: "0px", color: '#8cb1f3' }}>solicita registro</Link>.
                        </p>
                    </div>
                </div>
            </div >
        </section >

*/