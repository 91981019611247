import { memo, useEffect, useState } from "react";
import { useAuth } from "../../context/loginContext.js";
import { useDashboard } from "../../context/dashboardContext.js";
import { Link, useLocation } from "react-router-dom";
import Cookie from "js-cookie";

const NavBar = () => {
    //contexto de sesion
    const { setUser, setUser_autenticado, user_autenticado, user, obtener_data } = useAuth();
    //contexto de dash
    const { apartados, setApartados } = useDashboard();

    //const [apartados, setApartados] = useState("");
    let location = useLocation();

    const menu_on_off = () => {
        if (document.getElementById("body_main").classList.contains('sb-sidenav-toggled')) {
            document.getElementById("body_main").classList.remove("sb-sidenav-toggled");
        } else {
            document.getElementById("body_main").classList.add("sb-sidenav-toggled");
        }
    }

    const cerrar_sesion = () => {
        Cookie.remove("UStok");
        setUser(null);
        setUser_autenticado(false);
        //navigate("/login");
        //window.location.href = window.location.href;
        
    }

    /*
    useEffect(() => {

        if (location.pathname === "/home") {
            setApartados("home")
        }

        if (location.pathname === "/buscar") {
            setApartados("buscar")
        }
        if (location.pathname === "/cartera_asignada") {
            setApartados("cartera_asignada")
        }

        if (location.pathname === "/expediente") {
            setApartados("expediente")
        }

        if (location.pathname === "/transparencia") {

            setApartados("transparencia")


        }
        if (location.pathname === "/capacitacion") {

            setApartados("capacitacion")

        }

        if (location.pathname === "/otros") {

            setApartados("otros")

        }

        if (location.pathname === "/perfil") {

            setApartados("perfil")

        }


        //Advertising_campaigns

    }, [location.pathname]);
    */

    return (
        <>
            <nav className="sb-topnav navbar navbar-expand n-s-edit">

                <div className="container-fluid">
                    {/* Navbar Brand*/}
                    <div className="justify-content-center align-items-center d-flex">
                        <Link className="navbar-brand ps-3 " to={"/home"}>
                            <img src={"/logo.png"} alt="login form" title="Bienvenido al sistema" className="w-100" />
                        </Link>
                        {/* Sidebar Toggle  order-1 order-lg-0 me-4 me-lg-0*/}
                        <button className=" navbar-toggler butt_options" id="sidebarToggle" href="#!" onClick={() => { menu_on_off() }}><i className="fas fa-bars" /></button>
                    </div>

                    {/* Navbar Search*/}
                    <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">

                    </form>
                    {/* Navbar*/}
                    <div className="w-100 justify-content-center align-items-center div_options">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown me-3">
                                <Link className={"nav-link " + (apartados === "home" ? "active_link_top" : "")} to={"/home"} >
                                    <div className="sb-nav-link-icon"><i className="bi bi-house-door" /> <b>Inicio</b></div>
                                </Link>

                            </li>
                            <li className="nav-item dropdown me-3">
                                <Link className={"nav-link " + (apartados === "buscar" ? "active_link_top" : "")} to={"/buscar"} >
                                    <div className="sb-nav-link-icon"><i className="bi bi-search" /> <b>Buscar</b></div>
                                </Link>
                            </li>

                            <li className="nav-item dropdown me-3">
                                <div className="dropdown">

                                    <button className={"nav-link " + (apartados === "cartera_asignada"  || apartados === "expediente" ? "active_link_top" : "")} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="sb-nav-link-icon dropdown-toggle"><i className="bi bi-collection-fill" /> <b>Cartera</b></div>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <Link className={"dropdown-item "+ (apartados === "cartera_asignada" ? "active_link_sider" : "hove_option")} to={"/cartera_asignada"} >
                                                Asignación
                                            </Link>
                                        </li>
                                        <li><Link className={"dropdown-item "+ (apartados === "expediente" ? "active_link_sider" : "hove_option")} type="button" to={"/expediente"}>Ultimo expediente abierto</Link></li>
                                        <li><button className="dropdown-item hove_option" type="button">Log de expedientes</button></li>
                                    </ul>
                                </div>
                            </li>
                            {/**
                             <li className="nav-item dropdown me-3">
                                <Link className={"nav-link " + (apartados === "cartera_asignada" ? "active_link_top" : "")} to={"/cartera_asignada"} >
                                    <div className="sb-nav-link-icon"><i className="bi bi-collection-fill" /> <b>Cartera</b></div>
                                </Link>
                            </li>
                             */}

                            <li className="nav-item dropdown me-3">
                                <Link className={"nav-link " + (apartados === "transparencia" ? "active_link_top" : "")} to={"/transparencia"} >
                                    <div className="sb-nav-link-icon"><i className="bi bi-bar-chart-line" /> <b>Transparencia</b></div>

                                </Link>
                            </li>
                            <li className="nav-item dropdown me-3">
                                <Link className={"nav-link " + (apartados === "capacitacion" ? "active_link_top" : "")} to={"/capacitacion"} >
                                    <div className="sb-nav-link-icon"><i className="bi bi-person-raised-hand" /> <b>Capacitación</b></div>
                                </Link>
                            </li>
                            <li className="nav-item dropdown me-3">
                                <Link className={"nav-link " + (apartados === "otros" ? "active_link_top" : "")} to={"/otros"} >
                                    <div className="sb-nav-link-icon"><i className="bi bi-node-plus" /> <b>Otros</b></div>
                                </Link>
                            </li>

                        </ul>
                    </div>

                    <ul className="navbar-nav me-3 me-lg-4">
                        <li className="nav-item dropdown">
                            <button className={"nav-link " + (apartados === "perfil" ? "active_link_top" : "")} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                
                                <div className="sb-nav-link-icon dropdown-toggle"><i className="fas fa-user fa-fw" /></div>
                                </button>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><Link className={"dropdown-item "+ (apartados === "perfil" ? "active_link_sider" : "hove_option")} to={"/perfil"}><i className="bi bi-person-badge"></i> Perfil</Link></li>
                                <li><button className="dropdown-item hove_option" onClick={()=>{cerrar_sesion()}} ><i className="bi bi-power"></i> Salir</button></li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </nav>

        </>
    );




}

export default memo(NavBar);