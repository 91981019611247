import { memo, useEffect } from "react";
import NavBar from "../AdminLTE/navbar";
import SideBar from "../AdminLTE/siderbar";
import Footer from "../AdminLTE/footer";
import GraphicsHighcharts from "../graficas_highcharts/graficas_highcharts.js";
const Transparencia = () => {
    var mostrar_data_pie = true;

    if (window.screen.width < 1150) {
        mostrar_data_pie = false;
    }

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 titulo001">Transparencia</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active subtitulo001">Aqui podras ver tu avance de cobranza del mes, asi como ver la competencia entre compañeros.</li>
            </ol>
            
            <div className="row  g-4">
                <div className="col-md-6">
                    <div className="card" style={{ padding: "20px" }}>
                        <label className="titulo002"> Datos Generales</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card" style={{ padding: "20px" }}>

                        <GraphicsHighcharts type="pie" config={([
                            {
                                type: "meta_avance",
                                filtro: ""
                            },

                            //aqui indicamoos que tipos de graficos veremos y cuales campos de athena seran sus datos
                            {


                                titulo_grafica: "Cobranza individual",

                                //dentro de aqui pondremos todos los "objetos graficos" que necesitemos 
                                partes_pie: {

                                    titulo_general: "Avance", // titulo de referencia del valor

                                    fuentes_columnas_datos: {
                                        name_columna_generador_partes: "meta", //nombre de la columna o campo de la tabla de athena, aqui indicamos de donde se van a calcular las barras y sus nombres
                                        name_columna_valores: "porcentaje"  // nombre de la columna o campo de la tabla de athena,  aqui indicamos de donde tomara los valores de cada "fuente".
                                    },

                                    estilo: [

                                        {
                                            name_fuente: "Faltante",
                                            color: "#F1948A",
                                        },
                                        {
                                            name_fuente: "Alcanzado",
                                            color: "#17A589",
                                        }

                                    ]
                                }
                            },

                            //esto es para el alto y ancho de la grafica y alineacion de los titulos.
                            {
                                posicion_titulos: "abajo",// "arriba" "abajo"
                                estilo_pipe: {
                                    caracter_add_valor: "%",
                                    valor_visible: mostrar_data_pie,
                                    dimencional: false,
                                }
                            }
                        ])} />

                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card" style={{ padding: "20px" }}>

                        <GraphicsHighcharts type="multi_graficos" config={([
                            {
                                type: "meta_avance_proyectos",
                                filtro: ""
                            },

                            //aqui indicamoos que tipos de graficos veremos y cuales campos de athena seran sus datos
                            {


                                titulo_grafica: "Cobranza global",

                                eje_y: {
                                    titulo: "Porcentaje",
                                    color: "black"
                                },

                                eje_x: {
                                    name_columna: "Proyecto", //columnada o campo de la tabla de athena
                                    color: "black"
                                },



                                interaccion_barras: "divididas", // aqui indicamos como se van a comportar las barras si "divididas" O "unidas"

                                //dentro de aqui pondremos todos los "objetos graficos" que necesitemos 
                                objetos_graficos: [
                                    {
                                        titulo: "Porcentaje de cobranza actual",
                                        name_columna: "porcentaje", //columnada o campo de la tabla de athena
                                        color: "#F08080", //color del grafico
                                        tipo: "column" //tipo de grafico, se puede "column" "line" "area"
                                    },

                                    {
                                        titulo: "# Ejecutivos",
                                        name_columna: "personas",
                                        color: "#5D6D7E",
                                        tipo: "line"
                                    }

                                ],
                            },

                            //esto es para el alto y ancho de la grafica
                            {
                                posicion_titulos: "abajo",// "arriba" "abajo"
                                estilo_pipe: {
                                    caracter_add_valor: "%",
                                }
                            }
                        ])} />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Transparencia);