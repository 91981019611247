import { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDashboard } from "../../context/dashboardContext.js";

const SideBar = () => {

    //contexto de dash
    const { apartados, setApartados } = useDashboard();

    const [hora, setHora] = useState("");

    //const [apartados, setApartados] = useState("");
    let location = useLocation();
    document.getElementById("body_main").classList.add("body_liso");

    const menu_on_off = () => {
        if (document.getElementById("body_main").classList.contains('sb-sidenav-toggled')) {
            document.getElementById("body_main").classList.remove("sb-sidenav-toggled");
        } else {
            document.getElementById("body_main").classList.add("sb-sidenav-toggled");
        }

        if(document.getElementById("collapseLayouts").classList.contains("show") && (apartados !== "cartera_asignada" && apartados !== "expediente" ) ){
            document.getElementById("collapseLayouts").classList.remove("show");
        }else if((apartados === "cartera_asignada" || apartados === "expediente" ) ){
            document.getElementById("collapseLayouts").classList.add("show");
        }
    }
    /*
    useEffect(() => {

        if (location.pathname === "/home") {
            setApartados("home")
        }

        if (location.pathname === "/buscar") {
            setApartados("buscar")
        }
        if (location.pathname === "/cartera_asignada") {
            setApartados("cartera_asignada")
        }

        if (location.pathname === "/expediente") {
            setApartados("expediente")
        }

        if (location.pathname === "/transparencia") {

            setApartados("transparencia")


        }
        if (location.pathname === "/capacitacion") {

            setApartados("capacitacion")

        }

        if (location.pathname === "/otros") {

            setApartados("otros")

        }

        if (location.pathname === "/perfil") {

            setApartados("perfil")

        }

        if(!location.pathname.includes("cartera_asignada") && !location.pathname.includes("expediente") ){
            document.getElementById("collapseLayouts").classList.remove("show");
            console.log("activado");
        }else if(location.pathname.includes("cartera_asignada") || location.pathname.includes("expediente") ){
            document.getElementById("collapseLayouts").classList.add("show");
            console.log("apagado");
        }


        //Advertising_campaigns

    }, [location.pathname]);
    */

    if (true) {

        return (
            <>
                <div id="layoutSidenav_nav">
                    <nav className="sb-sidenav accordion n-s-edit" id="sidenavAccordion">
                        <div className="sb-sidenav-menu">
                            <div className="nav">
                                <div className="sb-sidenav-menu-heading">Menu</div>
                                <Link className={"nav-link " + (apartados === "home" ? "active_link_sider" : "")} to={"/home"} onClick={() => { menu_on_off() }}>
                                    <div className="sb-nav-link-icon"><i className="bi bi-house-door" /></div>
                                    <b>Inicio</b>
                                </Link>
                                <Link className={"nav-link " + (apartados === "buscar" ? "active_link_sider" : "")} to={"/buscar"} onClick={() => { menu_on_off() }}>
                                    <div className="sb-nav-link-icon"><i className="bi bi-search" /></div>
                                    <b>Buscar</b>
                                </Link>
                                <Link className={"nav-link " + (apartados === "cartera_asignada" || apartados === "expediente" ? "active_link_sider" : " collapsed")} data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div className="sb-nav-link-icon "><i className="bi bi-collection-fill" /></div>
                                    <b>Cartera</b>
                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                                </Link>

                                <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav className="sb-sidenav-menu-nested nav">
                                        <Link className={"nav-link " + (apartados === "cartera_asignada" ? "active_link_sider_sub" : "")} to={"/cartera_asignada"} onClick={() => { menu_on_off() }}>
                                            Asignación
                                        </Link>
                                        <Link className={"nav-link "+ (apartados === "expediente" ? "active_link_sider_sub" : "")} to={"/expediente"} type="button" onClick={() => { menu_on_off() }}>Ultimo expediente</Link>
                                        <button className="nav-link " type="button">Log de expedientes</button>
                                    </nav>
                                </div>


                                <Link className={"nav-link " + (apartados === "transparencia" ? "active_link_sider" : "")} to={"/transparencia"} onClick={() => { menu_on_off() }}>
                                    <div className="sb-nav-link-icon"><i className="bi bi-bar-chart-line" /></div>
                                    <b>Transparencia</b>
                                </Link>
                                <Link className={"nav-link " + (apartados === "capacitacion" ? "active_link_sider" : "")} to={"/capacitacion"} onClick={() => { menu_on_off() }}>
                                    <div className="sb-nav-link-icon"><i className="bi bi-person-raised-hand" /></div>
                                    <b>Capacitación</b>
                                </Link>
                                <Link className={"nav-link " + (apartados === "otros" ? "active_link_sider" : "")} to={"/otros"} onClick={() => { menu_on_off() }}>
                                    <div className="sb-nav-link-icon"><i className="bi bi-node-plus" /></div>
                                    <b>Otros</b>
                                </Link>



                            </div>
                        </div>
                        <div className="sb-sidenav-footer">
                            <div className="small">:::</div>
                            {hora}
                        </div>
                    </nav>
                </div>

            </>
        );
    }



}

export default memo(SideBar);