import { memo, useState } from "react";
const Buscar = () => {
    const [tipo, setTipo] = useState(1);
    const [ocultar, setOcultar] = useState(false);

    const activar_estadisticas = () => {
        if (ocultar) {
            document.getElementById("cerrar_icon").classList.add("but_switch_graficas_off");
            document.getElementById("cerrar_icon").classList.remove("but_switch_graficas_on");
            setOcultar(false);
        } else {
            document.getElementById("cerrar_icon").classList.remove("but_switch_graficas_off");
            document.getElementById("cerrar_icon").classList.add("but_switch_graficas_on");
            setOcultar(true);
        }
    }

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 titulo001">Buscar</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active subtitulo001">Si tienes los datos de algún expediente, aquí podrás encontrarlo.</li>
            </ol>
            {/**
                        <div className="card">
                            <div className="card-body">
                            </div>
                        </div>
                    */}

            <div className="container">
                <div className="col-12">
                    <div className="card">
                        <div className={"card-body "+ (ocultar ? "p-4": "")}>
                            <div className="but_switch_graficas_off" id="cerrar_icon" style={{ position: "absolute", top: "2px", right: "10px", fontSize: "1.8rem" }}>
                                <i className="ion-android-arrow-dropup-circle" onClick={() => { activar_estadisticas(); }} />
                            </div>
                            {
                                !ocultar ?
                                    <>
                                        <div className="col-md-4">
                                            <label>Tipo</label>
                                            <select className="form-select" onChange={(e) => { Number(e.target.value) === 1 ? setTipo(1) : setTipo(2) }}>
                                                <option value={1}>Por datos generales</option>
                                                <option value={2}>Por comprobante</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="card bg-w">
                                                <div className="card-body">
                                                    {
                                                        tipo === 1 ?
                                                            <div className="row">
                                                                <div className="col-sm-12  mb-4 text-secondary">
                                                                    <i className="bi bi-info-circle"></i> Puedes buscar un expediente con los siguientes tipos de datos, no es necesario llenar todos los campos, pero entre más campos llenes, más precisa es la búsqueda.<br></br>
                                                                    El campo debe estar vacío si quieres que <b>no se tome en cuenta.</b>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>Expediente</label>
                                                                    <input type="tel" className="form-control"></input>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>No. Cuenta</label>
                                                                    <input type="tel" className="form-control"></input>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>RFC</label>
                                                                    <input type="text" className="form-control"></input>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>Telefono</label>
                                                                    <input type="tel" className="form-control"></input>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row">
                                                                <div className="col-sm-12  mb-4 text-secondary">
                                                                    <i className="bi bi-info-circle"></i> Puedes buscar un expediente con los siguientes tipos de datos, no es necesario llenar todos los campos, pero entre más campos llenes, más precisa es la búsqueda.<br></br>
                                                                    El campo debe estar vacío si quieres que <b>no se tome en cuenta.</b>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>Estatus del Comprobante</label>
                                                                    <select className="form-select">
                                                                        <option disabled>.:Selecciona:.</option>
                                                                        <option value={1}>Autorizado</option>
                                                                        <option value={2}>Por Autorizar</option>
                                                                        <option value={3}>En Revisión</option>
                                                                        <option value={4}>No Autorizado</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>Beneficiario</label>
                                                                    <select className="form-select">
                                                                        <option disabled>.:Selecciona:.</option>
                                                                        <option value={1}>Metro Invest, S.A. DE C.V.</option>
                                                                        <option value={2}>Adeudos Vencidos, S.A. DE C.V.</option>
                                                                        <option value={3}>AXIS Logistica y Distribucion, S.A. DE C.V.</option>
                                                                        <option value={4}>Asesores Bona Fide, S.A. DE C.V.</option>
                                                                        <option value={5}>Cuentas Vencidas de Occidente, S.A. DE C.V.</option>
                                                                        <option value={6}>Administradora Mexiquense, S.A. DE C.V.</option>
                                                                        <option value={7}>F Cobranza Comercial, S.A. DE C.V.</option>
                                                                        <option value={8}>Administradora de Créditos Pasivos, S.A. de C.V.</option>
                                                                        <option value={9}>Administradora De Pasivos Del Centro, S.A. de C.V.</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>Fecha del pago inicial</label>
                                                                    <input type="date" className="form-control"></input>
                                                                </div>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>Fecha del pago final</label>
                                                                    <input type="date" className="form-control"></input>
                                                                </div>
                                                            </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-end mt-3">
                                            <button className="btn btn-pay"><i className="bi bi-search"></i> Buscar</button>
                                        </div>
                                    </>
                                    :
                                    <>
                                    <label>Los filtros de búsqueda están ocultos, dale clic en el botón <i className="ion-android-arrow-dropup-circle"/> para visualizarlos.</label>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Buscar);