import { memo } from "react";
import { Link } from "react-router-dom";
const CardAsigExp = (params) => {

    let data = params.data;

    const parse_date = (date) => {
        var day = new Date(date).getUTCDate();
        var mes = new Date(date).getUTCMonth() + 1;
        var ano = new Date(date).getUTCFullYear();
        if (day < 10) {
            day = "0" + day;
        }
        if (mes < 10) {
            mes = "0" + mes;
        }
        return (ano + "-" + mes + "-" + day);
    }

    const abrir_exp_car = async () => {
        // abrir_expediente(data.Id_Cuenta);
    }


    return (
        <div className="col-lg-12">
            <div className="card border-dark card_zoom">
                <div className="card-header" style={{ paddingTop: "0", paddingBottom: "0" }}>
                    <div className="row">
                        <div className="col-sm-6" style={{ marginTop: "10px" }}>
                            <label style={{ marginBottom: "0", fontWeight: "bold" }}>Expediente: </label> {(typeof data.id_cuenta === "undefined" ? data.Id_Cuenta : data.id_cuenta)}
                        </div>
                        <div className="col-sm-6" style={{ textAlign: "right", marginTop: "10px", marginBottom: "10px" }}>
                            {/***
                             * 
                             *  <a href={window.location.origin+"/only/exp?data="+(typeof data.id_cuenta === "undefined" ? data.Id_Cuenta : data.id_cuenta)} target="_blank" className="btn btn-outline-secondary btn-sm mr-1" ><i className="bi bi-folder-symlink-fill"></i> ver externo</a>
                             * 
                             */}
                            <button href={window.location.origin + "/only/exp?data=" + (typeof data.id_cuenta === "undefined" ? data.Id_Cuenta : data.id_cuenta)} target="_blank" className="btn btn-outline-secondary btn-sm me-1" disabled><i className="bi bi-segmented-nav"></i> Pestaña</button>
                            <button className="btn btn-outline-secondary btn-sm me-1" onClick={() => { window.open(window.location.origin + "/only/exp?data=" + (typeof data.id_cuenta === "undefined" ? data.Id_Cuenta : data.id_cuenta), "mozillaWindow", "popup"); }} disabled><i className="bi bi-window-plus"></i> Ventana</button>
                            <Link className="btn btn-outline-secondary btn-sm " to={"/expediente"} disabled><i className="bi bi-file-earmark-text"></i> Ver</Link>
                        </div>
                    </div>
                </div>
                <div className="card-body" style={{ paddingTop: "0", paddingBottom: "5px" }}>
                    <table className="table-hover">
                        <tbody>
                            <tr>
                                <th>Cuenta:</th>
                                <td>{data.Cuenta}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "200px" }}>Titular:</th>
                                <td>{data.Nombre_Titular}</td>
                            </tr>
                            <tr>
                                <th>RFC:</th>
                                <td>{data.RFC}</td>
                            </tr>
                            <tr>
                                <th>Usuario Asignado:</th>
                                <td>{data.Ejecutivo}</td>
                            </tr>
                            <tr>
                                <th>Ultima nota:</th>
                                <td>{data.Fecha_Nota !== null ? ((new Date(data.Fecha_Nota)).getUTCFullYear()).toString() + "/" + (((new Date(data.Fecha_Nota)).getUTCMonth() + 1) < 10 ? "0" + ((new Date(data.Fecha_Nota)).getUTCMonth() + 1) : ((new Date(data.Fecha_Nota)).getUTCMonth() + 1)).toString() + "/" + (((new Date(data.Fecha_Nota)).getUTCDate()) < 10 ? "0" + ((new Date(data.Fecha_Nota)).getUTCDate()) : ((new Date(data.Fecha_Nota)).getUTCDate())).toString() : "---"}</td>
                            </tr>
                        </tbody>

                    </table>

                    <div className="row d-flex justify-content-center align-items-center" style={{ marginTop: "20px" }}>
                        <div className="col-sm-4">
                            <i className="bi bi-telephone"></i> {(typeof data.telefono === "undefined" ? data.Telefono : data.telefono)}
                        </div>
                        <div className="col-sm-4 text-center" >
                            <label style={{ marginBottom: "0", fontWeight: "bold" }}>Cobranza: </label> ${data.Cobranza}
                        </div>
                        <div className="col-sm-4" style={{textAlign: "right"}}>
                            <label style={{ marginBottom: "0", fontWeight: "bold" }}>Saldo Total: </label> ${data.SaldoTotal}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export default memo(CardAsigExp);